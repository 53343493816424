import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'sanitize' })

export class SanitizePipe implements PipeTransform {

  constructor(private _domSanitizer: DomSanitizer) { }

  transform(value: string, type: string): SafeHtml | SafeStyle | SafeUrl | SafeResourceUrl {

    switch (type) {
      case 'html': return this._domSanitizer.bypassSecurityTrustHtml(value);
      case 'style': return this._domSanitizer.bypassSecurityTrustStyle(value);
      case 'url': return this._domSanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl': return this._domSanitizer.bypassSecurityTrustResourceUrl(value);
      default: throw new Error(`Invalid safe type specified: ${type}`);
    }

  }
}