import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SanitizePipe } from '@app/core/pipes/sanitize-data.pipe'
import { NgClass, NgStyle, NgTemplateOutlet, AsyncPipe, DecimalPipe, TitleCasePipe, UpperCasePipe, DatePipe } from '@angular/common';
import { NgIconComponent } from '@ng-icons/core';
import { NgxMaskDirective } from 'ngx-mask';
import { SearchPipe, SearchMessagesPipe } from '@app/core/pipes/search.pipe';

@NgModule({
    declarations: [SanitizePipe, SearchPipe, SearchMessagesPipe],
    imports: [
        CommonModule,
        TranslateModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgClass, 
        NgStyle, 
        NgTemplateOutlet, 
        AsyncPipe, 
        DecimalPipe, 
        TitleCasePipe,
        UpperCasePipe,
        DatePipe,
        NgIconComponent,
        NgxMaskDirective
    ],
    exports: [
        TranslateModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        SanitizePipe,
        NgClass, 
        NgStyle, 
        NgTemplateOutlet, 
        AsyncPipe, 
        DecimalPipe, 
        TitleCasePipe,
        UpperCasePipe,
        DatePipe,
        NgIconComponent,
        NgxMaskDirective,
        SearchPipe,
        SearchMessagesPipe
    ],
})
export class SharedModule {}